<template>
  <div>
    <a-modal v-model="modalVisible" :title="modalTitle" :width="1300" :centered="true" :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalVisible=false">{{ showType == 'detail' ? '关闭' : '取消' }}</a-button>
        <a-button v-if="showType=='add' || showType=='edit'|| showType=='paste'" type="primary" @click="confirm()">
          提交
        </a-button>
      </template>
      <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" :model="formData" :rules="formRules"
                    :label-col="{span:6}" :wrapper-col="{span:16}">
        <a-form-model-item label="项目名称" prop="userdep_id">
          <a-select :disabled="showType=='detail'" v-model="formData.userdep_id" placeholder="请选择" show-search :filter-option="filterOption">

            <a-select-option v-for="(item, index) in monitorpointList" :key="index" :value="item.userdepid" >
              {{ item.monitorpointname }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="能耗类型" prop="type">
          <a-select :disabled="showType=='detail'" v-model.trim="formData.type">

            <a-select-option v-for="(item, index) in ecTypeList" :key="index" :value="item">{{ item }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="showType!=='add'" label="能耗表编号" prop="code">
          <a-input disabled v-model.trim="formData.code"/>
        </a-form-model-item>
        <a-form-model-item label="级数" prop="level">
          <a-select :disabled="showType=='detail'" v-model.trim="formData.level">

            <a-select-option v-for="(item, index) in ecLevelList" :key="index" :value="item">{{ item }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

<!--        <a-form-model-item label="图位号" prop="map_location_code">-->
<!--          <a-input :disabled="showType=='detail'" v-model.trim="formData.map_location_code"/>-->
<!--        </a-form-model-item>-->
        <a-form-model-item label="仪表名称" prop="meter_name">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.meter_name"/>
        </a-form-model-item>
        <a-form-model-item label="表号" prop="meter_code">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.meter_code"/>
        </a-form-model-item>
        <a-form-model-item label="能耗表名称(出厂名称)" prop="ec_meter_name">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.ec_meter_name"/>
        </a-form-model-item>
        <a-form-model-item label="规格型号" prop="model">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.model"/>
        </a-form-model-item>
        <a-form-model-item label="分组名" prop="group_id">
          <a-select :disabled="showType=='detail'" v-model.trim="formData.group_id">
            <a-select-option value="">无</a-select-option>
            <a-select-option v-for="(item, index) in ecGroupData" :key="index" :value="item.id">{{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="制造厂商" prop="manufacturer">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.manufacturer"/>
        </a-form-model-item>
        <a-form-model-item label="等级" prop="grade">
          <a-select :disabled="showType=='detail'" v-model.trim="formData.grade">

            <a-select-option v-for="(item, index) in ecGradeList" :key="index" :value="item">{{ item }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="形式" prop="form">
          <a-select :disabled="showType=='detail'" v-model.trim="formData.form">

            <a-select-option v-for="(item, index) in ecMeterFormAllList" :key="index" :value="item">{{ item }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment'
import {mapState} from 'vuex'
import areaselect from '@/mixins/areaselect'
import deptselect from '@/mixins/deptselect'
import {getMonitorPointNameListByCondition} from "A/monitoring";
import {
  addEcRecord,
  editEcRecord,
} from "A/energyconsumption";

import ecCommont from "../mixins/ecMixin"

export default {
  mixins: [areaselect, deptselect, ecCommont],
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    },
    ecGroupData: {
      default: null
    }
  },
  data() {
    return {
      moment,
      modalBodyStyle: {
        height: '300px',
        overflowY: 'auto',
      },
      modalVisible: false,
      formData: {
        monitorpointnum: '',
        code: '',
        type: '',
        level: '',
        meter_name: '',
        meter_code: '',
        ec_meter_name: '',
        model: '',
        group_id: '',
        grade: '',
        form: '',
        manufacturer: '',
        userdep_id: '',
        //map_location_code:""
      },
      //这里面的数据属于必填项
      formRules: {
        userdep_id: [{required: true, message: '请选择项目'}],
        //code: [{required: true, message: ''}],
        type: [{required: true, message: '请选择能耗类型'}],
        level: [{required: true, message: '请选择级数'}],
        meter_name: [{required: true, message: '请输入仪表名称'}],
        meter_code: [{required: true, message: '请输入表号'}],
        ec_meter_name: [{required: true, message: '请输入能耗表名称'}],
        model: [{required: true, message: '请输入规格型号'}],
        //group_id: [{required: true, message: '请选择分组'}],
        manufacturer: [{required: true, message: '请输入制造厂商'}],
        grade: [{required: true, message: '请选择等级'}],
        form: [{required: true, message: '请选择形式'}],
      },
      monitorpointList: [],
      equipmentNumList: [],

      ecMeterFormAllList: [],
      ecGradeList: [],
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    modalTitle() {
      if (this.showType === 'add') {
        return '新增'
      } else if (this.showType === 'edit') {
        return '修改'
      } else if (this.showType === 'detail') {
        return '详情'
      } else {
        return '';
      }
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.modalVisible = true;
        this.resetData();
        this.getMonitorPointNameList();
        this.initDetail();
      }
    },
    modalVisible(val) {
      if (!val) {
        this.resetData();
        this.$emit('get-operation-result', 'success', '操作成功');
        this.$emit('update:visible', false);
      }
    },
    'formData.userdep_id'(val) {
      //this.formData.monitorpointnum = ''
      for (let item of this.monitorpointList) {
        if (item.userdepid === val) {
          this.formData.monitorpointnum = item.monitorpointnum
        }
      }
    },
    'formData.type'(val) {
      if (val && val.length) {
        //this.formData.code=this.ecCodeContrastList[val]
        this.ecMeterFormAllList = this.ecMeterForm[val];
        this.ecGradeList = this.ecMeterLevel[val]
        if( this.detailData && val!==this.detailData.type){
          this.formData.grade=''
          this.formData.form=''
        }
      } else {
        //this.formData.code = ''
        this.ecMeterFormAllList = []
        this.ecGradeList = []
      }

    }
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    onChange(option) {
      this.formData.host_num = option.replace(/\s*/g, "")
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    getMonitorPointNameList() {
      getMonitorPointNameListByCondition({userdepsid: this.userInfo.userdepid}).then(res => {
        if (res && res.returncode == '0') {
          this.monitorpointList = res.item
        }
      })
    },
    resetData() {
      if (this.$refs.modalForm) {
        this.formData.userdep_id = ''
        this.$refs.modalForm.resetFields();
      }
    },
    initDetail() {
      if (this.detailData && this.detailData.id) {
        if (this.showType == 'edit' || this.showType == 'detail') {
          console.log(this.detailData)
          this.formData.id = this.detailData.id
          this.formData.code = this.detailData.code
          this.formData.type = this.detailData.type
          this.formData.level = this.detailData.level
          this.formData.meter_name = this.detailData.meter_name
          this.formData.meter_code = this.detailData.meter_code
          this.formData.ec_meter_name = this.detailData.ec_meter_name
          this.formData.model = this.detailData.model
          this.formData.group_id = this.detailData.group_id
          this.formData.grade = this.detailData.grade
          this.formData.form = this.detailData.form
          this.formData.manufacturer = this.detailData.manufacturer
          this.formData.userdep_id = this.detailData.userdep_id
          this.formData.monitorpointnum = this.detailData.monitorpointnum
          //this.formData.map_location_code = this.detailData.map_location_code
        } else {
          this.resetData()
        }
      }
    },
    confirm() {
      if (this.showType === 'add' || this.showType === 'edit') {
        this.$refs.modalForm.validate(valid => {
          this.formData.code=this.ecCodeContrastList[this.formData.type]
          if (valid) {
            let params = {
              ...this.formData,
              //userdepsid:this.formData.userdep_id
            };
            if (this.showType === 'add') {
              this.showLoading();
              addEcRecord(params).then(res => {
                this.hideLoading();
                if (res && res.returncode === '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            } else if (this.showType === 'edit') {
              this.showLoading();
              //此处判断一下，能耗类型是否改变了
              params.code=this.detailData.code
              if(this.formData.type!==this.detailData.type){
                  params.code=this.ecCodeContrastList[this.formData.type]
              }

              editEcRecord(params).then(res => {
                this.hideLoading();
                if (res && res.returncode === '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            } else {
              this.$message.warning('请确认信息填写完整')
            }
          } else {
            this.$message.warning('请确认信息填写完整')
            return false;
          }
        })
      } else {
        this.modalVisible = false;
      }
    },
    // searchFilterOption(input, option){
    //   console.log(input);
    //   console.log(option);
    // }

  }
}
</script>
<style lang="scss" scoped>
.select-drop-down-platform {
  width: 240px;
}
</style>