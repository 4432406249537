<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="monitorpointname" style="width: 25%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }"
                          :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')"
                          v-model="userdepidCascaderSelected"
                          :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll"
                          placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="能耗类型" prop="type" style="width: 25%">
              <a-select v-model="queryParams.type">
                <a-select-option value="">全部</a-select-option>
                <a-select-option v-for="(item, index) in ecTypeList" :key="index" :value="item">{{ item }}
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item label="能耗表编号" prop="code" style="width: 25%">
              <a-input v-model="queryParams.code"></a-input>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 20%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开
                <a-icon :type="showAdvanced?'up':'down'"></a-icon>
              </a-button>
            </div>
            <div v-show="showAdvanced" class="advanced-query">
              <a-form-model-item label="形式" prop="form" style="width: 25%">
                <a-select v-model="queryParams.form">
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option v-for="(item, index) in ecMeterFormAllList" :key="index" :value="item">{{ item }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="等级" prop="grade" style="width: 25%">
                <a-select v-model="queryParams.grade">
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option v-for="(item, index) in ecGradeList" :key="index" :value="item">{{ item }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
<!--              <a-form-model-item label="级数" prop="level" style="width: 25%">-->
<!--                <a-select v-model="queryParams.level">-->
<!--                  <a-select-option value="">全部</a-select-option>-->
<!--                  <a-select-option v-for="(item, index) in ecLevelList" :key="index" :value="item">{{ item }}-->
<!--                  </a-select-option>-->
<!--                </a-select>-->
<!--              </a-form-model-item>-->

<!--              <a-form-model-item label="图位号" prop="map_location_code" style="width: 25%">-->
<!--                <a-input v-model="queryParams.map_location_code"></a-input>-->
<!--              </a-form-model-item>-->
              <a-form-model-item label="仪表名称" prop="meter_name" style="width: 25%">
                <a-input v-model="queryParams.meter_name"></a-input>
              </a-form-model-item>
              <a-form-model-item label="能耗表名称" prop="ec_meter_name" style="width: 25%">
                <a-input v-model="queryParams.ec_meter_name"></a-input>
              </a-form-model-item>
<!--              <a-form-model-item label="分组名" prop="group_name" style="width: 25%">-->
<!--                <a-select v-model="queryParams.group_name">-->
<!--                  <a-select-option value="">全部</a-select-option>-->
<!--                  <a-select-option v-for="(item, index) in ecGroupList" :key="index" :value="item.name">{{ item.name }}-->
<!--                  </a-select-option>-->
<!--                </a-select>-->
<!--              </a-form-model-item>-->
              <a-form-model-item label="制造厂商" prop="manufacturer" style="width: 25%">
                <a-input v-model="queryParams.manufacturer"></a-input>
              </a-form-model-item>
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">能耗表台账</h3>
          <div class="table-btns">
            <a-button type="primary" @click="showModal('add')">
              <a-icon type="plus"></a-icon>
              新增
            </a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :customRow="customRow" :columns="tableColumns" :data-source="tableData" row-key="id"
                 :loading="tableLoading"
                 :scroll="{ x: 2000}"
                 :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')"
                 style="margin-top: 20px;" :pagination="false">
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.id">详情</a-menu-item>
                <a-menu-item :key="'edit-'+record.id">修改</a-menu-item>
                <a-menu-item :key="'delete-'+record.id">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer
                      @change="pageChange" @showSizeChange="onShowSizeChange"
                      :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
        <add-or-edit-modal :visible.sync="modalVisible" :show-type="modalShowType" :detailData="modalDetailData" :ecGroupData="ecGroupList" @get-operation-result="getTableData"></add-or-edit-modal>
  </page-container>
</template>
<script>
import moment from "moment";
import {mapGetters, mapState} from "vuex";
import pagination from "@/mixins/pagination";
import {getCache, getItemFromArrayByKey, getTimeRange} from "U/index";
import deptselect from "@/mixins/deptselect";
import {
  deleteOneEcRecord,
  getEcRecordListByCondition,
  getEcGroupNamesListByCondition, getAllocationRecordByRecordId
} from "A/energyconsumption";
import addOrEditModal from "./addOrEditModal";
import ecCommont from "../mixins/ecMixin"

export default {
  name: "directories",
  mixins: [deptselect, pagination, ecCommont],
  components: {
    addOrEditModal,
  },
  data() {
    return {
      moment,
      tableData: [],
      tableLoading: false,
      showAdvanced: false,
      queryParams: {
        code: '',
        type: '',
        level: '',
        meter_name: '',
        meter_code:'',
        ec_meter_name:'',
        model:'',
        group_name:'',
        grade:'',
        form:'',
        manufacturer:'',
        map_location_code:''
      },
      tableColumns: [
        {
          title: '项目名称',
          dataIndex: 'userdepname',
          key: 'userdepname',
          ellipsis: true,
        },
        {
          title: '能耗表编号',
          dataIndex: 'code',
          key: 'code',
          ellipsis: true,
        },
        // {
        //   title: '图位号',
        //   dataIndex: 'map_location_code',
        //   key: 'map_location_code',
        //   ellipsis: true,
        // },
        {
          title: '能耗类型',
          dataIndex: 'type',
          key: 'type',
          ellipsis: true,
        },
        {
          title: '级数',
          dataIndex: 'level',
          key: 'level',
          ellipsis: true,
        },

        {
          title: '仪表名称',
          dataIndex: 'meter_name',
          key: 'meter_name',
          ellipsis: true,
        },
        {
          title: '表号',
          dataIndex: 'meter_code',
          key: 'meter_code',
          ellipsis: true,
        },
        {
          title: '能耗表名称(出厂名称)',
          dataIndex: 'ec_meter_name',
          key: 'ec_meter_name',
          ellipsis: true,
        },
        {
          title: '规格型号',
          dataIndex: 'model',
          key: 'model',
          ellipsis: true,
        },
        {
          title: '分组名',
          dataIndex: 'group_name',
          key: 'group_name',
          ellipsis: true,
        },
        {
          title: '制造厂商',
          dataIndex: 'manufacturer',
          key: 'manufacturer',
          ellipsis: true,
        },
        {
          title: '等级',
          dataIndex: 'grade',
          key: 'grade',
          ellipsis: true,
        },
        {
          title: '形式',
          dataIndex: 'form',
          key: 'form',
          ellipsis: true,
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          ellipsis: true,
          align: 'center',
          scopedSlots: {customRender: 'operation'},
          width: 70
        },
      ],
      ecMeterFormAllList: [],
      ecGradeList:[],
      ecGroupList:[],


      copyData: {},
      userdepidCascaderSelected: [],
      deviceCodeList: [],
      equipmentStatusList: [],
      equipmentCategoryMap: {},
      equipmentCategoryList: [],
      equipmentChildCategoryList: [],
      equipmentChildCategoryMap: [],
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      //操作按钮权限
      btnList: [],
      menu: {},
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['operationMenuTree']),
    pageTitle() {
      return "能耗表台账"
    },
    breadcrumb() {
      const pages = [{name: "设施设备管理", path: ""}]
      pages.push({name: "运行管理", path: ""})
      pages.push({name: "能耗管理", path: ""})
      pages.push({name: this.pageTitle, path: ""})
      return pages
    },
  },
  watch: {
    userdepidCascaderSelected(val) {
      if (val && val.length) {
        this.queryParams.userdepsid = val[val.length - 1];
      } else {
        this.queryParams.userdepsid = '';
      }
    },
    'queryParams.type'(val) {
      if (val && val.length) {
        this.queryParams.form=''
        this.queryParams.grade=''
        this.ecMeterFormAllList = this.ecMeterForm[val];
        this.ecGradeList = this.ecMeterLevel[val]
      } else {
        this.ecMeterFormAllList = []
        this.ecGradeList = []

      }
    },
  },
  created() {
    this.menu = getItemFromArrayByKey(this.operationMenuTree, "menuid", this.$route.params.menuid, "children")
    for (let i = 0; i < this.menu.children.length; i++) {//获取菜单页面内操作权限
      this.btnList.push(this.menu.children[i].menuname)
    }
    this.getTableData()
    this.initDeptOptionsAll();
    this.getEcGroupAll();
  },
  methods: {
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getEcRecordListByCondition(params).then(res => {
        this.tableLoading = false;
        if (res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    getEcGroupAll(){
      let params = {
      };
      getEcGroupNamesListByCondition(params).then(res => {
        if (res && res.returncode == '0') {
          if(res.item && res.item.length!==0){
            this.ecGroupList=res.item
          };
        }
      })
    },
    resetQueryParams() {
      this.userdepidCascaderSelected = []
      this.$refs.queryForm.resetFields();
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let id = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'id', id);
      if (type == 'delete') {
        this.deleteConfirm(id, record)
      } else {
        this.showModal(type, record)
      }
    },
    showModal(type, record) {
      if(type === 'add'){
        this.modalVisible=true
        this.modalShowType=type
      } else if (type === 'detail') {
        this.modalDetailData = record
        this.modalShowType = type
        this.modalVisible = true
      } else if (type === 'edit') {
        this.modalDetailData = record
        this.modalShowType = type
        this.modalVisible = true
      }
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据?', {
        type: 'warning',
        centered: true,
      }).then(() => {
        this.delete(record.id);
      }).catch(() => {

      });
    },
    delete(id) {
      this.showLoading();
      if (id) {
        let params = {
          id
        };
        //先查询这个能耗表是否被分配了
        getAllocationRecordByRecordId(params).then(res => {
          this.hideLoading();
          if (res && res.returncode == '0') {
            if(res.item && res.item.length!==0){
              this.$message.error('此能耗表已经分配，请先在能耗分配台账中解绑数据');
            }else{
              this.showLoading();
              deleteOneEcRecord(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.getTableData();
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            }
          } else {
            this.$message.error(res.errormsg || '操作失败');
          }
        })
      }
    },
    rowSelectChange(selectedRowKeys, selectedRows) {
      this.batchrowids = selectedRowKeys.join('|');
    },
    customRow(record, index) {
      return {
        on: { // 事件
          click: () => {
            this.modalDetailData = record
            this.modalShowType = "detail"
            this.modalVisible = true
          },
        },
      }
    },
  },
}
</script>
<style scoped>

</style>